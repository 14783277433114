@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap);
  html {
    box-sizing: border-box;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: #F2F2F2;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  a {
    text-decoration: none !important;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
